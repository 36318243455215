'use strict';

var Home = function() {
    var navButtons = $('[data-target]');
    var sections = $('[data-id]');
    var submit = $('.form__submit');

    submit.on('click', function(e){
        e.preventDefault();
    });

    navButtons.each(function(){
        var $this = $(this);
        var target = $this.data('target');

        $this.on('click', function(){
            sections.removeClass('-active');

            sections.filter(function(){
                return $(this).data('id') == target;
            }).addClass('-active');
        });
    });
}

module.exports = Home;
